import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import BaseRoutes from "./routes/index.js";
import "./App.scss";
import {
  setCredentials,
  clearCredentials,
} from "./core/store/redux/slices/Auth-slice.js";
import ApiClient from "./apis/Api-client.js";
import { END_POINT } from "./utils/End-points.js";
import { useNavigate } from "react-router-dom";

// Custom Hook to handle fetching user details
const useUserDetails = (sessionUserKey) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { api } = ApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async (user_key) => {
      try {
        const response = await api.post(
          END_POINT.USER.GET_USER_BY_FILTER,
          {
            filter: { user_key },
            fields: {
              user_key: true,
              hotel_id: {
                hotel_logo: true,
                hotel_id: true,
                hotel_name: true,
                hotel_address: true,
                hotel_contact_number: true,
                hotel_gstin_number: true,
                hotel_email: true,
                hotel_logo: true,
              },
              role_id: {
                role_name: true,
              },
            },
            relations: {
              hotel_id: true,
              role_id: true,
            },
          },
          "Error Fetching User Details"
        );
        return response;
      } catch (error) {
        console.error("Error fetching user details", error);
        return null;
      }
    };

    const handleUserAuthentication = async () => {
      if (sessionUserKey) {
        const response = await fetchUserDetails(sessionUserKey);
        if (response?.status === 200 && response?.data?.data.length) {
          const userData = response.data.data[0];
          dispatch(
            setCredentials({
              user: {
                user_key: userData.user_key,
                ...userData.role_id,
              },
              hotel: {
                ...userData.hotel_id,
                hotel_logo: userData.hotel_id.hotel_logo[0]?.url,
              },
            })
          );
        } else {
          dispatch(clearCredentials());
        }
      } else {
        dispatch(clearCredentials());
      }
      setLoading(false);
    };

    handleUserAuthentication();
  }, [sessionUserKey]);

  return loading;
};

function App() {
  const sessionUserKey = sessionStorage.getItem("user_key");

  // Use the custom Hook to manage user authentication and loading state
  const loading = useUserDetails(sessionUserKey);

  return loading ? (
    <div
      style={{ backgroundColor: "white", height: "100vh", width: "100vw" }}
    ></div>
  ) : (
    <BaseRoutes />
  );
}

export default App;
