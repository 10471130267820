import { useSelector } from "react-redux";
import { selectAuthData } from "../../../core/store/redux/slices/Auth-slice";



const AuthorizedComponent = ({ authorizedRoles, children }) => {
    const { role_name } = useSelector(selectAuthData).user;
    let current_roles = [role_name]
    // const { userRoles } = useContext(AuthContext);

    // Function to check if there is any matching role between allowedRoles and userRoles
    const hasPermission = () => {
        return current_roles.some(userRole =>
            authorizedRoles.some(allowedRole => userRole === allowedRole)
        );
    };

    // If there's a matching role, render the children components
    if (hasPermission()) {
        return <>{children}</>;
    }
};
export default AuthorizedComponent;
