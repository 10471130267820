export const THEME_CONFIG = {
    typography: {
        // font_family: "'Inter', sans- serif",
        // font_family_lato: "'Lato', sans-serif",
        fontFamily: "'Inter', sans- serif"
    },
    palette: {

        orange_theme: {
            primary: {
                main: '#D9A62E',
                light: '#FFE097',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#FFF6E8',
            },
            teritary: {
                main: '#DFA828'
            }
        },
        primary: {
            main: "#D9A62E"
        },
        success: {
            main: "#4caf50"
        },
        cancel: {
            main: "#d32f2f"
        }
    },
    // breakpoints: {
    //     values: {
    //         xxs: "240px",
    //         // =>  (min-width: 240) { ... }
    //         xs: "370px",
    //         // => @media (min-width: 320px) { ... }
    //         sm: "640px",
    //         // => @media (min-width: 640px) { ... }
    //         md: "768px",
    //         // => @media (min-width: 768px) { ... }
    //         lg: "1024px",
    //         // => @media (min-width: 1024px) { ... }
    //         xl: "1280px",
    //         // => @media (min-width: 1280px) { ... }
    //         xxl: "1400px",
    //         // => @media (min-width: 1280px) { ... }
    //     },
    // }
}