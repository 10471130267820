import * as Yup from "yup";

const loginFormConfig = Yup.object().shape({
    login_name: Yup.string().required("Please enter the username").trim(),
    password: Yup.string()
        .required("Please enter the password")
        .trim()
        .min(8, "Password must be minimum of 8 characters."),
});

export default loginFormConfig;
