// eslint-disable-next-line no-unused-vars
import style from "./Loader.scss";
import { usePromiseTracker } from "react-promise-tracker";

const Loader = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {promiseInProgress ? (
        <div
          style={{
            cursor: "pointer",
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
            zIndex: "1200",
          }}
        >
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Loader;
