import { useTheme } from "@emotion/react";
import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";

const MaintenanceDialog = ({
    handleCloseModal,
    modalFunction,
    value,
    setValue,
    reservedDate,
}) => {
    const themeStyles = useTheme();
    let primaryColor = themeStyles.palette.orange_theme.primary.main;
    let contrastText = themeStyles.palette.orange_theme.primary.contrastText;

    return (
        <Box>
            <textarea
                rows={8}
                maxLength={200}
                maxRows={4}
                onChange={(e) => setValue(e.target.value)}
                onInput={(e) => {
                    let { value } = e.target;
                    e.target.value = value.replace(/^\s+/, "");
                }}
                placeholder="Remarks"
                style={{
                    width: "100%",
                    margin: "5% 0",
                    fontSize: "14px",
                    outlineColor: "#BDBDBD",
                    borderColor: "#BDBDBD",
                    borderRadius: "4px",
                    padding: "2.5%",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "0 auto",
                }}
            >
                <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    sx={{
                        width: "48%",
                        color: primaryColor,
                        border: `1px solid ${primaryColor}`,
                        fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                        fontWeight: 700,
                    }}
                >
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    disabled={!value}
                    onClick={() => modalFunction()}
                    sx={{
                        width: "48%",
                        color: contrastText,
                        backgroundColor: primaryColor,
                        fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                        fontWeight: 700,
                    }}
                >
                    SAVE
                </Button>
            </Box>

            {reservedDate == null ? (
                <Typography
                    sx={{
                        color: "#D59600",
                        fontWeight: 400,
                        fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                        margin: "3% 0 0 0",
                    }}
                >
                    Please be aware of the dates to avoid scheduling issues.
                </Typography>
            ) : (
                <Typography
                    sx={{
                        color: "#D59600",
                        fontWeight: 400,
                        fontSize: 12,
                        margin: "3% 0 0 0",
                    }}
                >
                    This room has reservation on{" "}
                    {moment(reservedDate).format("DD-MM-YYYY")}. <br />
                    Please be aware of the dates to avoid scheduling issues.
                </Typography>
            )}
        </Box>
    );
};

export default MaintenanceDialog;
