import { Grid, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconConstants from "../../utils/IconConstants";
import ActionOptions from "./ActionOptions";
import ExtendStay from "../extendstay/Extend-stay.jsx";
import RoomShift from "../roomshift/Room-shift.jsx";
import PaymentHistory from "../paymenthistory/Payment-history.jsx";
import { END_POINT } from "../../utils/End-points.js";
import { Box, Modal, Fade, Backdrop } from "@mui/material";
import { useDispatch } from "react-redux";
import { setDashBoardData } from "../../core/store/redux/slices/Dashboard-slice.js";
import { clearReservationData } from "../../core/store/redux/slices/Reservation-slice.js";
import { clearCheckinData } from "../../core/store/redux/slices/Checkin-slice.js";
import ApiClient from "../../apis/Api-client.js";
import { useNavigate } from "react-router-dom";
import { setCheckinData } from "../../core/store/redux/slices/Checkin-slice.js";
import AddOrRemovePerson from "../addorremoveperson/AddOrRemovePerson.jsx";
import moment from "moment";
import {
  selectTriggered,
  setTriggered,
} from "../../core/store/redux/slices/DashboardApi-slice.js";
import { useSelector } from "react-redux";
import { errorToster, successToster } from "../../shared/toster/Toster.jsx";
import MaintenanceDialog from "./MaintenanceDialog.js";
import RecordPayment from "../common-components/record-payment/RecordPayment.jsx";
import {
  CLEANING_STATUSES,
  OCCUPANCY_STATUSES,
} from "../../utils/Constants.js";
const { ASSIGNED, CLEAN, DIRTY } = CLEANING_STATUSES;
const { CHECK_OUT, ROOM_SHIFT } = OCCUPANCY_STATUSES;

const IndividualRoomBox = ({
  item,
  searchText,
  searchFlag,
  handleResetFilters,
}) => {
  const { api } = ApiClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState();
  const menuOpen = Boolean(anchorEl);
  const isTriggered = useSelector(selectTriggered);
  const [maintenance_reason, setMaintenance_reason] = useState(null);
  const [menuIsNearRightEdge, setMenuIsNearRightEdge] = useState(false);

  useEffect(() => {
    dispatch(clearReservationData());
    dispatch(clearCheckinData());
  }, []);

  const handleClick = (event) => {
    handleResetFilters();
    setAnchorEl(event.currentTarget);
    // Calculate if the anchor element is near the right edge of the screen
    const anchorRect = event.currentTarget.getBoundingClientRect();
    const isNearRightEdge = window.innerWidth - anchorRect.right < 100;
    setMenuIsNearRightEdge(isNearRightEdge);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openpop = Boolean(anchorEl);
  const id = openpop ? "simple-popover" : undefined;

  const handleCloseModal = () => {
    setModalOpen(false);
    setAnchorEl(null);
  };

  const handleCancelModal = () => {
    setModalOpen(false);
    setAnchorEl(null);
    setMaintenance_reason(null);
    setModalType(null)
  };

  const renderActionModal = (modalType, data = null) => {
    setModalType(modalType);
    setModalOpen(true);
    setModalData(data);
  };

  const handleBookingNavigation = (bookingType) => {
    const params = new URLSearchParams();

    if (bookingType === "checkOut") {
      params.append(
        "occupancy_key",
        item.roomData.parent_occupancy_key ?? item.roomData.occupancy_key
      );
      navigate(`/main/departure?${params.toString()}`);
    } else if (bookingType === "reservedCheckIn") {
      params.append("reservation_key", item.roomData.reservation_key);
      navigate(`/main/arrival?${params.toString()}`);
    } else if (bookingType === "vacantCheckIn") {
      params.append("room_key", item.roomData.room_key);
      navigate(`/main/arrival?${params.toString()}`);
    } else if (bookingType === "reserve") {
      params.append("room_key", item.roomData.room_key);
      navigate(`/main/new-reservation?${params.toString()}`);
    }
    handleClose();
  };

  const handleGuestDetails = (route) => {
    dispatch(setDashBoardData(item.roomData));
    setTimeout(() => {
      navigate(`/main/${route}`);
    });
  };

  const handleRoomCleaning = async () => {
    try {
      let changeRes = await api.put(
        END_POINT.HOUSE_KEEPING.MOVE_ROOMS_TO_DIRTY,
        {},
        item.roomData.room_id
      );
      if (changeRes.status === 200) {
        dispatch(setTriggered(!isTriggered));
        handleCloseModal();
        successToster(
          <>
            Moved to dirty room successfully <br></br>{" "}
            <span style={{ color: "#62BA60" }}>
              {item?.roomData?.name} in room number: {item.roomData.room_no}
            </span>
          </>
        );
      }
    } catch (error) {
      console.error("Error handle room cleaning", error);
      errorToster("Error moving room to cleaning");
    }
  };

  const handleUnderMaintenance = async () => {
    try {
      handleCloseModal();
      const res = await api.put(
        END_POINT.ROOM.UPDATE_ROOM_MAINTENANCE,
        {
          is_under_maintenance: !item.roomData?.is_under_maintenance,
          maintenance_reason:
            item.roomData?.is_under_maintenance == 0
              ? maintenance_reason
              : null,
        },
        item.roomData?.room_id
      );
      if (res?.status === 200) {
        dispatch(setTriggered(!isTriggered));
        successToster(
          `Room ${item.roomData?.room_no} ${
            !item.roomData?.is_under_maintenance
              ? "blocked successfully"
              : "is ready to occupy."
          }`
        );
      }
    } catch (error) {
      console.error("Error handle room cleaning", error);
      errorToster("Error moving room to cleaning");
    }
  };

  const renderRoom = () => {
    switch (item?.flag) {
      case "Vacant":
        return {
          ...item,
          doorIcon: IconConstants.VacantDoor,
          isCleanIcon: item?.image,
          cleaningStatus: item?.cleaningStatus,
          isClean: true,
          actionBtnIcn: IconConstants.ReserveWhite,
          actionBtnName: "Reserve",
          actionBtnOnClick: () => {
            handleBookingNavigation("reserve");
            handleClose();
          },
          btnColor: "#62BA60",
          btnTextColor: "#FFFFFF",
          bgColor: "#DCFFDB",
          roomType: "Deluxe",
          roomData: item.roomData,
          options: [
            {
              name: "Check In",
              optClick: () => {
                handleBookingNavigation("vacantCheckIn");
              },
            },
            item?.roomData?.cleaning_status_code === CLEAN
              ? {
                  name: "Move to Dirty",
                  optClick: () => {
                    handleRoomCleaning();
                    handleClose();
                  },
                }
              : null,
            {
              name: "Block",
              optClick: (e) => {
                renderActionModal("maintenance", item?.roomData);
              },
            },
          ],
        };
      case "Reserved":
        return {
          ...item,
          doorIcon: IconConstants.ReservedDoor,
          isCleanIcon: item?.image,
          roomData: item.roomData,
          cleaningStatus: item?.cleaningStatus,
          isClean: true,
          actionBtnIcn: IconConstants.CheckIn,
          actionBtnName: "Check In",
          actionBtnOnClick: () => {
            handleBookingNavigation("reservedCheckIn");
          },
          btnColor: "#FFFFFF",
          btnTextColor: "#252525",
          bgColor: "#9F70FD",
          color: "#FFFFFF",
          options: [
            {
              name: "Guest Details",
              optClick: () => {
                handleGuestDetails("reservation");
              },
            },
            {
              name: "Record Payment",
              optClick: () => {
                renderActionModal("paymentRecord", item?.roomData);
                handleClose();
              },
            },
            {
              name: "Payment History",
              optClick: () => {
                renderActionModal("paymentHistory");
                handleClose();
              },
            },
            item?.roomData?.cleaning_status_code === CLEAN
              ? {
                  name: "Move to Dirty",
                  optClick: () => {
                    handleRoomCleaning();
                    handleClose();
                  },
                }
              : null,
          ],
        };
      case "Occupied":
        return {
          ...item,
          doorIcon: IconConstants.OccupiedDoor,
          isCleanIcon: item?.image,
          roomData: item.roomData,
          cleaningStatus: item?.cleaningStatus,
          isClean: false,
          actionBtnIcn: IconConstants.CheckoutRed,
          actionBtnName: "Check Out",
          actionBtnOnClick: () => {
            handleBookingNavigation("checkOut");
          },
          btnColor: "#ED705B",
          btnTextColor: "#FFFFFF",
          bgColor: "#FFEDEA",
          color: "#ED705B",
          options: [
            {
              name: "Guest Details",
              optClick: () => {
                handleGuestDetails("guestinfo/arrival");
              },
            },
            // {
            //     name: "Record Payment",
            //     optClick: () => {
            //         renderActionModal("paymentRecord", item?.roomData);
            //     },
            // },
            // {
            //     name: "Payment History",
            //     optClick: () => {
            //         renderActionModal("paymentHistory");
            //     },
            // },
            {
              name: "Room Shift",
              optClick: () => {
                renderActionModal("roomShift", {
                  ...item?.roomData,
                  room_rent: item?.roomData?.occupancy_price,
                  total_tax_rate: 12,
                  checkout_date: item?.roomData?.expected_checkout_date,
                  checkout_date: item?.roomData.expected_checkout_date,
                });
              },
            },
            item?.roomData?.cleaning_status_code === CLEAN
              ? {
                  name: "Move to Dirty",
                  optClick: () => {
                    handleRoomCleaning();
                  },
                }
              : null,
          ],
        };

      case "under_maintenance":
        return {
          ...item,
          isCleanIcon: IconConstants.BlockedIcon,
          roomData: item.roomData,
          cleaningStatus: item?.cleaningStatus,
          isClean: false,
          bgColor: "#FFFFFF",
          btnColor: "#DEA829",
          btnTextColor: "#FFFFFF",
          actionBtnName: "Ready to occupy",
          actionBtnOnClick: () => {
            handleUnderMaintenance();
          },
        };
      default:
        return {
          ...item,
        };
    }
  };
  let room = renderRoom();

  const handleOpenPersonPopover = (name) => {
    setModalType(name)
    setModalOpen(true)
  };

  return (
    <>
      <Grid
        item
        // xs={2.5}
        // md={1.4}
        sx={{
          width: { xs: "15.38vw", sm: "9.59vw", md: "5.56vw" },
          height: { xs: "10.8vw", sm: "7.19vw", md: "4.17vw" },
          cursor: "pointer",
          transition: "all 0.3s ease",
          border: item?.border,
          borderRadius: { xs: "6px", sm: "0.96vw", md: "0.56vw" },
          backgroundColor:
            (!item.isAllSelected && item.isSelected) || id
              ? item.filteredBackgroundColor
              : item.backgroundColor,
          filter:
            item.isSelected ||
            item.isAllSelected ||
            (searchFlag && item.roomNo === searchText)
              ? ""
              : "blur(3px)",
          opacity:
            item.isSelected ||
            item.isAllSelected ||
            (searchFlag && item.roomNo === searchText)
              ? 1
              : 0.9,
        }}
        id="action-room-button"
        aria-controls={menuOpen ? "action-room-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={(event) =>
          item.isSelected ||
          item.isAllSelected ||
          (searchFlag && item.roomNo === searchText)
            ? handleClick(event)
            : ""
        }
      >
        <Box
          sx={{
            width: "85%",
            height: "100%",
            margin: "3% auto",
            // display: (!item.isSelected && !!room.roomData.name) ? "block" : "flex",
            display:
              item?.houseKeepId == item.roomData.cleaning_status_id ||
              ![CHECK_OUT, ROOM_SHIFT].includes(
                room.roomData?.occupancy_status_code
              ) ||
              room.roomData.is_under_maintenance == 1
                ? "block"
                : "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {(item.isSelected && (!!item?.houseKeepId || !!item?.todaysFilter)) ||
          room.roomData.is_under_maintenance == 1 ? (
            <Box
              sx={{ marginBottom: { md: "5%" }, transition: "all 0.2s ease" }}
            >
              <Box
                sx={{
                  height:
                    room.roomData.is_under_maintenance == 1
                      ? { xs: "5.13vw", sm: "2.9vw", md: "1.65vw" }
                      : { xs: "5.13vw", sm: "3.36vw", md: "1.94vw" },
                  width:
                    room.roomData.is_under_maintenance == 1
                      ? { xs: "5.13vw", sm: "2.95vw", md: "1.73vw" }
                      : { xs: "5.13vw", sm: "3.36vw", md: "1.94vw" },
                }}
              >
                <img
                  src={
                    room.roomData.is_under_maintenance == 1 &&
                    (item.isSelected || id)
                      ? item.roomStatusIcon
                      : item?.image
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            </Box>
          ) : (
            ![CHECK_OUT, ROOM_SHIFT].includes(
              room.roomData?.occupancy_status_code
            ) &&
            room.roomData.is_under_maintenance == 0 && (
              <Box
                sx={{
                  marginBottom: { xs: "10%", md: "15%" },
                  transition: "all 0.2s ease",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "2.56vw", sm: "1.44vw", md: "0.83vw" },
                    color: item.isSelected || id ? "#FFFFFF" : item?.nameColor,
                    fontWeight: 500,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {room.roomData?.name}
                </Typography>
              </Box>
            )
          )}
          <Typography
            sx={{
              textAlign: "right",
              fontWeight: 700,
              color:
                (item.isSelected &&
                  !item?.houseKeepId &&
                  !item?.todaysFilter) ||
                id
                  ? "#FFFFFF"
                  : item?.color,
              fontSize: { xs: "3.08vw", sm: "2.4vw", md: "1.12vw" },
            }}
          >
            {item?.roomNo}
          </Typography>
        </Box>
      </Grid>
      <ActionOptions
        anchorEl={anchorEl}
        menuOpen={menuOpen}
        handleClose={handleClose}
        room={room}
        handleOpenPersonPopover={handleOpenPersonPopover}
        modalName={modalType}
        menuIsNearRightEdge={menuIsNearRightEdge}
      />
      <Modal
        open={modalOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              borderRadius: "16px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              padding: { xs: "4%", sm: "2.5%", md: "1.5%" },
              minWidth: { xs: "90%", sm: 0 },
            }}
          >
            {modalType === "paymentRecord" && (
              <RecordPayment
                handleCloseModal={handleCloseModal}
                bookingData={modalData}
              />
            )}
            {modalType === "extendStay" && (
              <ExtendStay
                bookingData={modalData}
                handleCloseModal={handleCloseModal}
              />
            )}
            {modalType === "roomShift" && (
              <RoomShift
                bookingData={modalData}
                handleCloseModal={handleCloseModal}
              />
            )}
            {modalType === "paymentHistory" && (
              <PaymentHistory
                bookingData={item?.roomData}
                roomBookingID={item?.roomData?.room_booking_id}
                getDataFromRoomBooking={true}
              />
            )}
            {modalType === "maintenance" && (
              <MaintenanceDialog
                handleCloseModal={handleCancelModal}
                modalFunction={handleUnderMaintenance}
                value={maintenance_reason}
                setValue={setMaintenance_reason}
                reservedDate={item?.roomData.checkin_date}
              />
            )}
            {(modalType === "Remove Person" || modalType === "Add Person") && (
              <AddOrRemovePerson
                modalName={modalType}
                handleClose={handleCancelModal}
                data={room?.roomData}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default IndividualRoomBox;
