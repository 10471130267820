import { Suspense } from "react";

export default function SuspenseFn(component) {
    return (
        <Suspense fallback={<></>}>
            {component}
        </Suspense>
    );


}