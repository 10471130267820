import * as Yup from 'yup';

const RecordPaymentConfig = (pendingAmount) => Yup.object().shape({
    payment_date: Yup.mixed(),
    payment_type_id: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Payment Mode is required"),
    paid_amount: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Amount is required")
        .max(pendingAmount, "Amount can't exceed the payment due."),
    remarks: Yup.string(),
});

export default RecordPaymentConfig;