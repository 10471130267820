import { Box, Typography } from "@mui/material";

export const AccessDenied = () => {
  return (
    <>
    <Box
      style={{
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography sx={{fontSize:{sm:"3.357vw", md:"2.222vw"}, fontWeight : 500}}>Access Denied !</Typography>
    </Box>
    </>
  );
};
