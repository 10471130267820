import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthData } from "../store/redux/slices/Auth-slice";

const PermissionGuard = ({ requiredRoles, children }) => {
  const { role_name } = useSelector(selectAuthData).user;

  // Check if the user's role is included in the required roles
  const isAllowed = requiredRoles.includes(role_name);

  if (!isAllowed) {
    return <Navigate to="/access-denied" />;
  }
  return <>{children}</>;
};

export default PermissionGuard;
