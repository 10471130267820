import React, { useEffect, useState } from "react";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  IconButton,
  Typography,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  ArrowDropDown,
  ArrowDropUp,
  Print,
  Visibility,
} from "@mui/icons-material";
import { Helper } from "../../utils/Helpers";
import { useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectAuthData } from "../../core/store/redux/slices/Auth-slice";
const {
  newPrintPaymentReceipt,
  viewPaymentReceipt,
  getDaysDifferenceByStayType,
  roundOff,
  truncateNumberByDecimal,
  getNetRetFromBasePrice,
  getLocalTimeFromUtc
} = new Helper();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.orange_theme.primary.main,
    color: theme.palette.orange_theme.primary.contrastText,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
      //   padding: "1.2%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
      //   padding: "1.5%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
    fontWeight: 400,
  },
}));

export const CollapsedTableCell = styled(TableCell)(({ theme, bodyWidth }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 500,
    paddingBottom: 0,
    border: 0,
    color: theme.palette.orange_theme.primary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#000000",
    border: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
    fontWeight: 400,
  },
}));

const PaymentHistory = ({ bookingData, actions }) => {
  const { name, booking_id, data } = bookingData || {};

  console.log("🚀 ~ PaymentHistory ~ data:", data)

  const { api } = ApiClient();
  const [PaymentHistory, setPaymentHistory] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [openRows, setOpenRows] = useState({});
  const [bookedRooms, setBookedRooms] = useState([])
  const [updatedData, setUpdatedData] = useState({})
  const { hotel } = useSelector(selectAuthData);

  const userData = hotel;
  const isNotMob = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const setInitialData = async (bookingId) => {
    try {
      const [paymentHistory, paymentDetail, bookedRooms] = await Promise.all([
        api
          .get(`${END_POINT.PAYMENT.GET_PAYMENT_HISTORY}${bookingId}`)
          .then((res) => res?.data?.data),
        api
          .get(`${END_POINT.PAYMENT.GET_PAYMENT_DETAIL_BY_BOOKING}${bookingId}`)
          .then((res) => res?.data?.data),
          api
          .post(`${END_POINT.ROOM.GET_BOOKED_ROOMS_BY_FILTER}`, {booking_id:bookingId}, '')
          .then((res) => res?.data?.data),
      ]);
      setPaymentHistory(paymentHistory);
      setPaymentDetail(paymentDetail);
      setUpdatedData({
        ...data,
        booked_rooms: bookedRooms
      })
      console.log(paymentDetail);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!!booking_id) {
      setInitialData(booking_id);
    }
  }, [booking_id]);

  const toggleRow = (index) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index],
    }));
  };

  const handleReceipt = (data, payment, receiptCallback) => {
    console.log("🚀 ~ handleReceipt ~ payment:", payment)
    console.log("🚀 ~ handleReceipt ~ data:", data)
    data.paid_amount = Number(payment?.paid_amount);
    data.payment_type = payment?.payment_type
    data.payment_date = payment?.payment_date;
    data.receipt_number = payment?.receipt_number;
    data?.booked_rooms?.forEach((room) => {
      
      const gstRate = Number(room?.total_tax_rate) / 100;
      const taxAmount = truncateNumberByDecimal(
        Number(room?.occupancy_price) * gstRate
      );
      const stayDays =
        room?.checkin_date &&
        room?.expected_checkout_date &&
        room?.stay_type_code
          ? getDaysDifferenceByStayType(
              getLocalTimeFromUtc(room.checkin_date?.replace('T', " ").split('.')[0]).dateTimeString,
              getLocalTimeFromUtc(room.expected_checkout_date?.replace('T', " ").split('.')[0]).dateTimeString,
              room.stay_type_code,
              0
            )
          : 0;
      const netRent = getNetRetFromBasePrice(
        Number(room?.occupancy_price),
        stayDays,
        Number(room?.total_tax_rate)
      );
      Object.assign(room, {
        sgst: room?.total_tax_rate / 2,
        cgst: room?.total_tax_rate / 2,
        taxAmount,
        netRent,
        stayDays,
      });
    });
    receiptCallback({
      billHeader: "ADVANCE RECEIPT",
      userData: userData,
      bookingData: data,
    });
  };

  return (
    <Box sx={{ minHeight: { md: "19.44vw", xs: "33.58vw" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1% 0 3% 0",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "4.10vw", sm: "1.92vw", md: "1.12vw" },
              fontWeight: 600,
            }}
          >
            Payments
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            width: "50%",
          }}
        >
          <Typography sx={{ fontSize: { md: "1.12vw", color: "#DFA928" } }}>
            {name || "-"}
          </Typography>
        </Box>
      </Box>
      <TableContainer
        sx={{
          maxHeight: { md: "19.44vw", sm: "33.58vw" },
          width: { sm: "max-content" },
          borderRadius: "4px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ textWrap: "nowrap" }}>
              <StyledTableCell>{isNotMob && "SI.No"}</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell>Amount Paid</StyledTableCell>
              {isNotMob && (
                <>
                  <StyledTableCell>Payment Mode</StyledTableCell>
                  <StyledTableCell>Remarks</StyledTableCell>
                </>
              )}
              {actions && (
                <StyledTableCell align="center">Action</StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {PaymentHistory?.length > 0 ? (
              PaymentHistory?.map((payment, ind) => (
                <>
                  <TableRow
                    sx={{
                      "& > *": {
                        borderBottom: "border: 1px solid #FFE097 !important",
                        backgroundColor: "#FCF9F2",
                      },
                    }}
                  >
                    {!isNotMob ? (
                      <StyledTableCell align="center">
                        <IconButton size="small" onClick={() => toggleRow(ind)}>
                          {openRows[ind] ? (
                            <ArrowDropUp
                              sx={{ color: "#D9A62E" }}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowDropDown
                              sx={{ color: "#D9A62E" }}
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="center">
                        {ind + 1}
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      align="center"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {!!payment?.payment_date
                        ? getLocalTimeFromUtc(payment?.payment_date?.split('.')[0]).invoice_date
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      ₹ {roundOff(payment?.paid_amount)}
                    </StyledTableCell>
                    {isNotMob && (
                      <>
                        <StyledTableCell>
                          {payment?.payment_type}
                        </StyledTableCell>
                        <StyledTableCell>
                          {payment?.remarks || "-"}
                        </StyledTableCell>
                      </>
                    )}
                    {actions && (
                      <StyledTableCell align="center">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() =>
                              handleReceipt(updatedData, payment, viewPaymentReceipt)
                            }
                            size="small"
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleReceipt(
                                updatedData,
                                payment,
                                newPrintPaymentReceipt
                              )
                            }
                            size="small"
                          >
                            <Print fontSize="small" />
                          </IconButton>
                        </Box>
                      </StyledTableCell>
                    )}
                  </TableRow>
                  <TableRow
                    sx={{
                      borderBottom: openRows[ind]
                        ? "none"
                        : "1px solid #FFEABA",
                      backgroundColor: "#FCF9F2",
                    }}
                  >
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        borderColor: "#FFEABA",
                      }}
                      colSpan={9}
                    >
                      <Collapse in={openRows[ind]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <CollapsedTableCell>
                                  Payment Mode
                                </CollapsedTableCell>
                                <CollapsedTableCell>Remarks</CollapsedTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <CollapsedTableCell sx={{ color: "#DEA829" }}>
                                  {payment.payment_type}
                                </CollapsedTableCell>
                                <CollapsedTableCell sx={{ color: "#DEA829" }}>
                                  {payment?.remarks || "-"}
                                </CollapsedTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  align="center"
                  sx={{ padding: "2%", color: "#bab9ba" }}
                >
                  {"No Data Found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          gap: "5%",
          margin: "2% 0",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
            color: "#DB563F",
          }}
        >{`Due: ₹ ${
          paymentDetail?.due_amount
            ? roundOff(paymentDetail?.due_amount)
            : "N/A"
        }`}</Typography>
        <Typography
          sx={{
            fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
            fontWeight: 500,
          }}
        >{`Total: ₹ ${
          paymentDetail?.total_amount
            ? roundOff(paymentDetail?.total_amount)
            : "N/A"
        }`}</Typography>
      </Box>
    </Box>
  );
};

export default PaymentHistory;
