import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

const ResultDlgBox = (props) => {

  setTimeout(() => {
    props?.open && props?.setOpen(false);
  }, props?.timeOut || 2000);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props?.open}
        onClose={() => props?.setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props?.open}>
          <Box
            sx={{
              height: "243px",
              width: "664px",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              outline: "none",
            }}
          >
            <Box>
              <img src={props?.infoIcon} alt="" />
            </Box>

            <Box sx={{ marginTop: "4%" }}>
              <Typography
                sx={{
                  color: "#CE8500",
                  fontWeight: 700,
                  justifyContent: "center",
                  fontSize: "24px",
                }}
              >
                {props?.label}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ResultDlgBox;
