import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import SuspenseFn from "../shared/suspense/Suspense.jsx";
import { PageNotFound } from "../components/not-found/Not-found.jsx";
import AuthGuard from "../core/auth-guard/Auth-guard.js";
import { Login } from "../pages/auth/login/Login.jsx";
import Register from "../pages/auth/register/Register.jsx";
// import CalendarView from "../pages/calendar/CalendarView.jsx"
import PermissonGuard from "../core/permission-guard/Permission-guard.js";
import { AccessDenied } from "../components/access-denied/Access-denied.jsx";
import SyncFloorRoom from "../pages/roomSetting/syncFloorRoom.jsx";
import ReceptionDashboard from "../pages/dashboard/ReceptionDashboard";
import { useSelector } from "react-redux";
import { selectAuthData } from "../core/store/redux/slices/Auth-slice.js";
import { USER_ROLES } from "../utils/Constants.js";
const { ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER } = USER_ROLES

const MainLayout = lazy(() => import("../pages/layout/Layout.jsx"));
const GuestInfo = lazy(() => import("../pages/guestinfo/GuestInfo.jsx"));
const RoomSetting = lazy(() => import("../pages/roomSetting/roomSetting.jsx"));
const RoomTypeForm = lazy(() => import("../pages/roomSetting/roomTypeForm.jsx"));
const Reservation = lazy(() => import("../pages/reservation/Reservation.jsx"));
const NewReservation = lazy(() => import("../pages/reservation/newReservation/NewReservation.jsx"));
const Arrival = lazy(() => import("../pages/arrival/Arrival.jsx"));
const Departure = lazy(() => import("../pages/departure/Departure.jsx"));
// const AlertSetting = lazy(() => import("../pages/alertsetting/AlertSetting.jsx"));
const UpdateProfile = lazy(() => import("../pages/updateprofile/UpdateProfile.jsx"));
const HouseKeepingEmployees = lazy(() => import("../pages/housekeeping/Employees.jsx"))
const HouseKeepingTasks = lazy(() => import("../pages/housekeeping/Tasks.jsx"))



function BaseRoutes() {

    const { role_name } = useSelector(selectAuthData)?.user ?? {};
    const isHouseKeepingManager = role_name === HOUSEKEEPING_MANAGER;

    const Auth = ({ children }) => {
        const isAuthenticated =  useSelector(selectAuthData).isAuthenticated;
        if (isAuthenticated) {
            return <Navigate to={ isHouseKeepingManager ? "/main/house-keeping-tasks" : "/main/dashboard"} />;
        }
        return <>{children}</>;
    };

    let routes = useRoutes([
        {
            path: "/",
            element: <Navigate to={ isHouseKeepingManager ? "/main/house-keeping-tasks" : "/main/dashboard"} replace />,
        },
        {
            path: "/auth",
            children: [
                {
                    path: "",
                    element: <Navigate to="login" replace />,
                },
                {
                    path: "login",
                    element:
                        <Auth>
                            <Login />
                        </Auth>
                },
                {
                    path: "register",
                    element: <Register />
                }
            ]
        },
        {
            path: "/main",
            element:
                <AuthGuard>
                    {SuspenseFn(<MainLayout />)}
                </AuthGuard>
            ,
            children: [
                {
                    path: "",
                    element: <Navigate to={isHouseKeepingManager ? "house-keeping-tasks" : "dashboard"} replace />,

                },
                {
                    path: "dashboard",
                    element:
                        <AuthGuard>
                            <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                            {SuspenseFn(<ReceptionDashboard />)}
                            </PermissonGuard>
                        </AuthGuard>
                },
                {
                    path: "guestinfo/:table_type?",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<GuestInfo />)}
                    </PermissonGuard>,
                },
                {
                    path: "facilitysettings",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<RoomSetting />)}
                    </PermissonGuard>,
                },
                {
                    path: "facilitysettings/addroomtype",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<RoomTypeForm />)}
                    </PermissonGuard>,
                },
                {
                    path: "facilitysettings/floorview",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<SyncFloorRoom />)}
                    </PermissonGuard>,
                },
                // {
                //     path: "reservation",
                //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                //         {SuspenseFn(<Reservation />)}
                //     </PermissonGuard>
                // },
                // {
                //     path: "new-reservation",
                //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                //         {SuspenseFn(<NewReservation />)}
                //     </PermissonGuard>,
                // },
                {
                    path: "arrival/:entity?",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<Arrival />)}
                    </PermissonGuard>,
                },
                {
                    path: "departure/:entity?",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<Departure />)}
                    </PermissonGuard>,
                },
                // {
                //     path: "calendar",
                //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                //         {SuspenseFn(<CalendarView />)}
                //     </PermissonGuard>,
                // },
                // {
                //     path: "alertsetting",
                //     element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                //         {SuspenseFn(<AlertSetting />)}
                //     </PermissonGuard>,
                // },
                {
                    path: "updateprofile",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST]}>
                        {SuspenseFn(<UpdateProfile />)}
                    </PermissonGuard>,
                },
                {
                    path: "house-keeping",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER]}>
                        {SuspenseFn(<HouseKeepingEmployees />)}
                    </PermissonGuard>,
                },
                {
                    path: "house-keeping-tasks",
                    element: <PermissonGuard requiredRoles={[ADMIN, RECEPTIONIST, HOUSEKEEPING_MANAGER]}>
                        {SuspenseFn(<HouseKeepingTasks />)}
                    </PermissonGuard>,
                }
            ],
        },
        {
            path: 'access-denied',
            element: <AccessDenied />
        },
        {
            path: "*",
            element: <PageNotFound />
        },
    ]);

    return routes;
}


export default BaseRoutes;
