import { createSlice } from "@reduxjs/toolkit";

const checkinSlice = createSlice({
    name: "checkin",
    initialState: {
        roomTypeData: null,
    },
    reducers: {
        setCheckinData: (state, action) => {
            state.checkinData = action.payload;
        },
        clearCheckinData: (state) => {
            state.checkinData = null;
        },
    },
});

export const { setCheckinData, clearCheckinData } = checkinSlice.actions;
export const selectCheckinData = (state) => state.checkin.checkinData;
export default checkinSlice.reducer;
