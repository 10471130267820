import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import registerFormConfig from '../../../config/forms/RegisterForm-config';
import { errorToster, infoToster, successToster, warnToster } from "../../../shared/toster/Toster";
import { Box, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import RegistrationForm from '../../../components/auth/register/Registration-form';
import "./Register.scss"

const CustomButton = styled(Button)(({ theme }) => ({

    color: theme.palette.orange_theme.primary.contrastText,
    backgroundColor: theme.palette.orange_theme.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.orange_theme.secondary.main,
        color: theme.palette.orange_theme.primary.main,
    },
    border: `1px solid ${theme.palette.orange_theme.primary.main}`,
    borderRadius: "4px",
    width: "100%",
    height: "44px",
    fontFamily: theme.typography.font_family,
    fontSize: "12px",
    paddingTop: "1%",
    paddingBottom: "1%",
    display: "block",
    fontWeight: "700",
}));

const Register = () => {
    const { register, control, handleSubmit, setError, formState: { errors } } = useForm({
        resolver: yupResolver(registerFormConfig),
        mode: "onTouched"
    });

    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        displayErrors(errors);
    }, [errors]);

    const displayErrors = (errorObject) => {
        console.log("errorObject>>>", errorObject)
        Object.keys(errorObject).forEach((key) => {
            warnToster(errorObject[key]?.message);
        });

    };

    const onSubmit = (data) => {
        console.log("data", data)

        if (data?.userName === "Ysquare" && data?.password === "Ysquare@123") {
            setIsVerified(true)
        }else{
            errorToster("Invalid UserName Or Password!")
        }
    };

    return (
        <>

            <Box
                className="register-outer-layout"
            >
                {isVerified ? 
                    <RegistrationForm/>
                    :
                    <Box
                        className="register-card-layout"
                    >

                        <Box className="register-typography">
                            <Box sx={{ fontFamily: (theme) => theme.typography.font_family, fontSize: "20px", fontWeight: "600", color: "#CC981C" }}>
                                Admin Registration
                            </Box>
                            <Box sx={{ fontSize: "16px", fontWeight: "600", fontFamily: (theme) => theme.typography.font_family }}>
                                Login To Register
                            </Box>
                        </Box>

                        <Box className="register-form-box">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box className="register-form-layout">
                                    <Box sx={{ width: "100%", height: "30%" }}>
                                        <TextField fullWidth size='small' label="User Name" {...register("userName")} />
                                    </Box>

                                    <Box sx={{ width: "100%", height: "30%" }}>
                                        <TextField type='password' fullWidth size='small' label="Password" {...register("password")} />
                                    </Box>

                                    <Box sx={{ width: "100%", height: "40%" }}>
                                        <CustomButton type='submit'> Login </CustomButton>
                                    </Box>
                                </Box>
                            </form>
                        </Box>

                    </Box>
                }

            </Box>

            { }
        </>
    )
}

export default Register