import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Collapse,
  Box,
  tableCellClasses,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconConstants from "../../utils/IconConstants";
import moment from "moment";
import { useMediaQuery } from "@mui/material";
import { Helper } from "../../utils/Helpers";

const { getLocalTimeFromUtc } = new Helper();
const StyledTableCell = styled(TableCell)(
  ({ theme, color, borderBottom, backgroundColor }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.orange_theme.primary.main,
      color: theme.palette.orange_theme.primary.contrastText,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 500,
      color: !!color ? color : "#707070",
      backgroundColor: backgroundColor,
      borderBottom: borderBottom,
      [theme.breakpoints.up("md")]: {
        fontSize: "0.83vw",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1.44vw",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.08vw",
      },
    },
  })
);
const CollapsedTableCell = styled(TableCell)(({ theme, bodyWidth }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 0,
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 500,
    width: bodyWidth,
    border: 0,
    color: "#252525",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.83vw",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.44vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.08vw",
    },
  },
}));

const Row = (props) => {
  const { row, paymentDetails, index } = props;
  console.log("🚀 ~ Row ~ row:", row);
  const [open, setOpen] = useState(false);
  const rowPaymentDetail = paymentDetails?.find(
    (payment) => payment?.room_booking_id === row?.room_booking_id
  );
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isTab = isSmUp && isMdDown;

  return (
    <>
      <TableRow
        sx={{
          borderBottom: !open && "1px solid #FFE9B6",
          backgroundColor: open && "#FFFBF0",
        }}
      >
        <StyledTableCell
          sx={{
            borderBottom: !open ? "1px solid #FFE9B6" : "none",
            backgroundColor: open && "#FFFBF0",
            paddingLeft: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: { sm: "3.6vw", md: "2.14vw" },
              height: { sm: "2.4vw", md: "1.43vw" },
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
              backgroundColor: "#4557FF",
              color: "#FFFFFF",
            }}
          >
            <Typography
              sx={{ fontSize: { sm: "1.44vw", md: "0.83vw" }, fontWeight: 500 }}
            >
              {row.booked_rooms.length
                ? row.booked_rooms.length.toString().padStart(2, "0")
                : "0"}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell
          borderBottom={!open ? "1px solid #FFE9B6" : "none"}
          color={open ? "#DEA829" : "#707070"}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
        >
          {index.toString().padStart(2, "0")}
        </StyledTableCell>
        <StyledTableCell
          borderBottom={"1px solid #FFE9B6"}
          color={open ? "#DEA829" : "#707070"}
        >
          {row?.name.length > 15 ? `${row?.name.slice(0, 15)}...` : row?.name}
        </StyledTableCell>
        <StyledTableCell
          borderBottom={"1px solid #FFE9B6"}
          color={open ? "#DEA829" : "#707070"}
        >
          {row?.mobile}
        </StyledTableCell>

        <StyledTableCell
          backgroundColor={open && "#FFFBF0"}
          align="center"
          borderBottom={!open ? "1px solid #FFE9B6" : "none"}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <KeyboardArrowUp sx={{ color: "#252525" }} />
            ) : (
              <KeyboardArrowDown sx={{ color: "#B5B5B5" }} />
            )}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow
        sx={{
          borderBottom: open && "1px solid #FFEABA",
          backgroundColor: open && "#FFFBF0",
        }}
      >
        <TableCell
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            border: "none",
            backgroundColor: open && "#FFFBF0",
          }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {row.booked_rooms.map((room, index) => (
                  <TableRow>
                    <CollapsedTableCell
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "8%",
                        paddingX: 0,
                      }}
                    >
                      <Box
                        sx={{
                          border: "0.5px solid #D9A62E",
                          borderRadius: "50%",
                          width: { xs: "8.33vw", sm: "4.79vw", md: "2.8vw" },
                          height: { xs: "8.33vw", sm: "4.79vw", md: "2.8vw" },
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "2.56vw",
                              sm: "1.44vw",
                              md: "0.83vw",
                            },
                            color: "#252525",
                            fontWeight: 600,
                          }}
                        >
                          {room?.room_no || "-"}
                        </Typography>
                      </Box>
                      {room?.room_type_name || "-"}
                    </CollapsedTableCell>
                    <CollapsedTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ color: "#878787" }}>Pax:</span>{" "}
                        <img
                          src={
                            open
                              ? IconConstants.Adult
                              : IconConstants.AdultBlack
                          }
                          alt=""
                        />
                        {room.no_of_adults > 0
                          ? room.no_of_adults.toString().padStart(2, "0")
                          : 0}
                        <img
                          src={
                            open
                              ? IconConstants.Children
                              : IconConstants.ChildrenBlack
                          }
                        />
                        {room.no_of_children > 0
                          ? room.no_of_children.toString().padStart(2, "0")
                          : 0}
                      </Box>
                    </CollapsedTableCell>
                    <CollapsedTableCell
                      sx={{ textWrap: "nowrap", paddingX: 0 }}
                    >
                      {getLocalTimeFromUtc(room?.actual_checkout_date)?.time ||
                        getLocalTimeFromUtc(room?.expected_checkout_date)?.time}
                    </CollapsedTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const DepartureActivityTable = ({ tableData = [], paymentDetail }) => {
  return (
    <Table size="small" aria-label="collapsible table">
      <TableBody>
        {tableData.length > 0 ? (
          tableData.map((row, rowIndex) => (
            <Row
              key={rowIndex}
              index={(rowIndex + 1).toString().padStart(2, "0")}
              row={row}
              paymentDetails={paymentDetail}
            />
          ))
        ) : (
          <TableCell
            align="center"
            sx={{
              paddingY: { md: "18vh" },
              color: "#bab9ba",
              border: "none",
              fontSize: { xs: "3.08vw", sm: 14 },
            }}
          >
            {"No Data Found"}
          </TableCell>
        )}
      </TableBody>
    </Table>
  );
};

export default DepartureActivityTable;
