import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconConstants from '../../utils/IconConstants';
import './Toaster.scss'

const toster_config = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
};

export const successToster = (msg) => {
    return toast.success(msg, {
        ...toster_config,
        style: {width: "max-content", color:"black", backgroundColor: "#DFFFE5", borderLeft: "20px solid #62BA60", borderRadius: "12px", fontWeight: 500 },
        icon: ({ theme, type }) => (
            <img src={IconConstants.SuccessToaster} alt="" style={{ width: "40px", height: "40px" }} />
        ),
    });
};

export const errorToster = (msg) => {
    return toast.error(msg, {
        ...toster_config,
        style: { backgroundColor: "#FFCBC2", borderLeft: "20px solid #ED705B", borderRadius: "12px", fontWeight: 500 },
        icon: ({ theme, type }) => (
            <img
                src={IconConstants.ToastErrorIcon}
                alt=""
                style={{ width: "25px", height: "25px", marginRight: "10px" }}
            />
        ),
    });
};

export const deleteToster = (msg) => {
    return toast.error(msg, {
        ...toster_config,
        style: { color:"#000000", width: "max-content", backgroundColor: "#FFCBC2", borderLeft: "20px solid #ED705B", borderRadius: "12px", fontWeight: 500 },
        icon: ({ theme, type }) => (
                <img
                    src={IconConstants.ToastDeleteIcon}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                />
        ),
    });
};

export const warnToster = (msg) => {
    return toast.warn(msg, {
        ...toster_config,
        style: { backgroundColor: "#F2E1B8", borderLeft: "20px solid #D9A62E", borderRadius: "12px", fontWeight: 500, width: "fit-content" },
        icon: ({ theme, type }) => (
            <img
                src={IconConstants.ToastWarnIcon}
                alt=""
                style={{ width: "25px", height: "25px" }}
            />
        )
    })
};

export const infoToster = (msg) => {
    return toast.info(msg, toster_config);
};


export const Toster = () => {
    return (
        <ToastContainer position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="colored"
            style={{width:"max-content"}}
        />
    );
};