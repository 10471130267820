import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Select, MenuItem, FormControl, Input, Typography } from '@mui/material';
import ApiClient from '../../../apis/Api-client'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import AdminRegFormConfig from '../../../config/forms/AdminRegistrationForm-config';
import UserRegFormConfig from '../../../config/forms/UserRegistrationForm-config';
import { styled } from '@mui/material/styles';
import { END_POINT } from '../../../utils/End-points';
import { errorToster, successToster, warnToster } from '../../../shared/toster/Toster';
import { useNavigate } from "react-router-dom";


let formFields = {
    admin: {
        fields: [

            {
                label: "Hotel Name",
                value: "hotel_name",
                type: "text",
                maxLength: 30
            },
            {
                label: "Hotel Address",
                value: "hotel_address",
                type: "text",
                maxLength: 150

            },
            {
                label: "Hotel Email",
                value: "hotel_email",
                type: "email",
                maxLength: 40

            },
            {
                label: "Hotel LandLine Number",
                value: "hotel_land_line_number",
                type: "number",
                maxLength: 11

            },
            {
                label: "Hotel Contact Number",
                value: "hotel_contact_number",
                type: "number",
                maxLength: 10

            },
            {
                label: "Hotel Logo",
                value: "hotel_logo",
                type: "hotel_logo"

            },
            {
                label: "User Name",
                value: "name",
                type: "text",
                maxLength: 20

            },
            {
                label: "User Email",
                value: "email",
                type: "email",
                maxLength: 40
            },
            {
                label: "User Contact Number",
                value: "contact",
                type: "number",
                maxLength: 10
            },
            {
                label: "Login Name",
                value: "login_name",
                type: "text",
                maxLength: 20

            },
            {
                label: "Password",
                value: "password",
                type: "password",
                maxLength: 12

            },
            {
                label: "Confirm Password",
                value: "confirmPassword",
                type: "password",
                maxLength: 12

            }
        ],
        apiEndpoint: END_POINT.AUTH.HOTEL_REGISTRATION,
        label: "Hotel"
    },

    receptionist: {
        fields: [
            {
                label: "Hotel",
                value: "hotel_id",
                type: "select_hotel"
            },
            {
                label: "User Name",
                value: "name",
                type: "text",
                maxLength: 20
            },
            {
                label: "User Email",
                value: "email",
                type: "email",
                maxLength: 40

            },
            {
                label: "User Contact Number",
                value: "contact",
                type: "number",
                maxLength: 10
            },
            {
                label: "Login Name",
                value: "login_name",
                type: "text",
                maxLength: 20

            },
            {
                label: "Password",
                value: "password",
                type: "password",
                maxLength: 12

            },
            {
                label: "Confirm Password",
                value: "confirmPassword",
                type: "password",
                maxLength: 12
            }

        ],
        apiEndpoint: END_POINT.AUTH.USER_REGISTRATION,
        label: "receptionist"

    },

    housekeeping_manager: {
        fields: [
            {
                label: "Hotel",
                value: "hotel_id",
                type: "select_hotel"
            },
            {
                label: "User Name",
                value: "name",
                type: "text",
                maxLength: 20
            },
            {
                label: "User Email",
                value: "email",
                type: "email",
                maxLength: 40

            },
            {
                label: "User Contact Number",
                value: "contact",
                type: "number",
                maxLength: 10
            },
            {
                label: "Login Name",
                value: "login_name",
                type: "text",
                maxLength: 20

            },
            {
                label: "Password",
                value: "password",
                type: "password",
                maxLength: 12

            },
            {
                label: "Confirm Password",
                value: "confirmPassword",
                type: "password",
                maxLength: 12
            }

        ],
        apiEndpoint: END_POINT.AUTH.USER_REGISTRATION,
        label: "housekeeping_manager"

    }

}

const CustomButton = styled(Button)(({ theme }) => ({

    color: theme.palette.orange_theme.primary.contrastText,
    backgroundColor: theme.palette.orange_theme.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.orange_theme.secondary.main,
        color: theme.palette.orange_theme.primary.main,
    },
    border: `1px solid ${theme.palette.orange_theme.primary.main}`,
    borderRadius: "4px",
    width: "50%",
    height: "44px",
    fontFamily: theme.typography.font_family,
    fontSize: "12px",
    paddingTop: "1%",
    paddingBottom: "1%",
    display: "block",
    fontWeight: "700",
}));

const RegistrationForm = () => {
    const { api } = ApiClient()
    const [hotels, setHotels] = useState()
    const [roles, setRoles] = useState()
    const [selectionValue, setSelectionValue] = useState("admin")
    const [currentFields, setCurrentFields] = useState(formFields[selectionValue].fields)
    const [currentRole, selectCurrentRole] = useState({})
    const [image, setImage] = useState(null);
    const [defaultRole, setDefaultRole] = useState()

    const { register, control, handleSubmit, setError, formState: { errors }, reset } = useForm({
        resolver: yupResolver(selectionValue === 'admin' ? AdminRegFormConfig : UserRegFormConfig),
        mode: "onTouched"
    });

    const navigate = useNavigate();


    useEffect(() => {
        if (!hotels) {
            setInitialData()
        }
    }, [hotels])



    const setInitialData = async () => {
        try {
            let hotelRes = await api.get(END_POINT.HOTEL.GET_ALL_HOTEL)
            let rolesRes = await api.get(END_POINT.ROLE.GET_ALL_ROLES)
            setHotels(hotelRes?.data?.data)
            setRoles(rolesRes?.data?.data)
            selectCurrentRole(rolesRes?.data?.data?.find((role) => role.role_name.toLowerCase() === selectionValue.toLowerCase()))
            // selectCurrentRole(() => { return rolesRes?.data?.data?.find((role) => role.role_name === selectionValue)})

        } catch (error) {
            console.log("errors>>", error)
        }
    }

    const onSubmit = async (data) => {
        try {
            let response
            if (data?.password === data?.confirmPassword) {

                if (selectionValue === 'admin') {
                    data["hotel_logo"] = image
                }
                console.log("typeof image>>>>", typeof image)
                delete data.confirmPassword
                if (selectionValue === 'admin') {
                    const FormData = require("form-data");
                    const formDataInstance = new FormData();
                    formDataInstance.append("data", JSON.stringify({ ...data, role_id: currentRole?.role_id }));
                    formDataInstance.append("hotel_logo", image);
                    response = await api.postFormData(formFields[selectionValue].apiEndpoint, formDataInstance, `Unable To Register ${formFields[selectionValue].label}`)
                } else {
                    response = await api.post(formFields[selectionValue].apiEndpoint, { ...data, role_id: currentRole?.role_id }, `Unable To Register ${formFields[selectionValue].label}`)
                }

                if (response) {
                    successToster(`${formFields[selectionValue].label} Registered Successfully`)
                    reset()
                    navigate("/auth/login")
                }
            } else {
                warnToster("Password Mismatched")
            }

        } catch (error) {
            errorToster("User Registration Failed")
        }

    };

    const handleFormSelection = (selectionValue) => {
        setSelectionValue(selectionValue)
        setCurrentFields(formFields[selectionValue].fields)
        reset();
        selectCurrentRole(roles?.find((role) => role.role_name.toLowerCase() === selectionValue.toLowerCase()))
    }


    const handleImageSelect = (event) => {
        const imageInput = event.target;
        if (imageInput.files && imageInput.files.length > 0) {
            const imageFile = imageInput.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);
            reader.onload = function () {
                const image = new Image();
                image.src = reader.result;
                image.onload = function () {
                    const canvas = document.createElement('canvas');
                    const maxWidth = 500; // Set your desired maximum width
                    const maxHeight = 500; // Set your desired maximum height
                    let width = image.width;
                    let height = image.height;

                    // Calculate new dimensions while maintaining aspect ratio
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);

                    // Convert canvas to compressed data URL
                    canvas.toBlob(function (blob) {
                        setImage(blob);
                    }, 'image/jpeg', 0.7);
                };
            };
        }
    }


const toPascalCase = (str) => {
    return str
        .split('_') // Split by underscores or adjust if different delimiter
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
};

    return (
        <Box
            sx={{
                height: "580px",
                borderRadius: "20px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                boxShadow: "0px 12px 10px 1px rgba(0, 0, 0, 0.25)",
                display: "flex",
                flexDirection: "column",
                padding: "2% 2.5%",
                gap: "10px"
            }}
        >

            <Box sx={{ width: "100%", textAlign: "center" }} >
                <Typography sx={{ fontSize: "20px", fontWeight: 600, color: "#CC981C" }}>Registration</Typography>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    textAlign: "center"
                }}
            >
                <FormControl sx={{ width: "50%" }}>
                    <TextField
                        value={selectionValue}
                        onChange={(e) => handleFormSelection(e.target.value)}
                        select
                        label="Select Role"
                        size="small"
                        sx={{ width: "100%" }}
                    >
                        {roles?.map((role, ind)=>
                        <MenuItem key={ind} value={role.role_name}>{toPascalCase(role.role_name)}</MenuItem>
                        )}
                    </TextField>
                </FormControl>
            </Box>

            <Box
                sx={{
                    height: "85%",
                    width: "100%",
                }}
            >
                <form style={{ width: "100%", height: "95%" }} onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            display: "flex", flexWrap: "wrap", flexDirection: "column", width: "100%", height: "100%", outline: "none", padding: "1%", gap: "3px", paddingLeft: "5%"
                        }}
                    >

                        {currentFields?.map((field) => (
                            <Box sx={{ height: "15%" }}>
                                <label style={{ fontSize: "medium", fontFamily: "'Inter', sans- serif" }}>{field.label}</label>
                                {field.type === "select_hotel" ?
                                    <select
                                        style={{ borderRadius: "5px", width: "90%", height: "60%", border: "1px solid #A7A7A7" }}
                                        onFocus={(e) => e.target.style.outline = "2px solid #FFE097"}
                                        onBlur={(e) => e.target.style.outline = "1px solid #A7A7A7"}
                                        {...register(field.value)}
                                    >
                                        {hotels && hotels?.map((hotel) => (
                                            <option value={hotel?.hotel_id}>{hotel?.hotel_name}</option>
                                        ))}
                                    </select>
                                    :
                                    field.type === "hotel_logo" ?
                                        <input
                                            autoComplete="off"
                                            style={{ borderRadius: "5px", width: "90%", height: "60%", border: "1px solid #A7A7A7" }}
                                            type="file"
                                            onChange={handleImageSelect}
                                            onFocus={(e) => e.target.style.outline = "2px solid #FFE097"}
                                            onBlur={(e) => e.target.style.outline = "1px solid #A7A7A7"}
                                        />
                                        :
                                        <input
                                            className="no-spinner"
                                            autoComplete="off"
                                            style={{ borderRadius: "5px", width: "90%", height: "60%", border: "1px solid #A7A7A7" }}
                                            onInput={(e) => {
                                                const { value } = e.target;
                                                e.target.value =
                                                    ((field.value === "hotel_contact_number") || (field.value === "contact"))
                                                        ? value.replace(/[^0-9]/g, "").slice(0, 10) :
                                                        (field.value === "hotel_land_line_number") ? value.replace(/[^0-9]/g, "").slice(0, 11)
                                                            : (field.type === "text") && (field.value !== "hotel_address") // Use && instead of &
                                                                ? value.replace(/[^A-Za-z\s]/g, "").replace(/^\s+/, "")
                                                                : (field.type === "password" || field.type === "email") ? value.trim().replace(/\s/g, "") : value;
                                            }}
                                            maxLength={field.maxLength ? field.maxLength : ''}
                                            type={field.type}
                                            {...register(field.value)}
                                            onFocus={(e) => e.target.style.outline = "2px solid #FFE097"}
                                            onBlur={(e) => e.target.style.outline = "1px solid #A7A7A7"}

                                        />

                                }
                            </Box>
                        ))
                        }
                    </Box>
                    <Box sx={{ height: "5%", marginBottom: "2%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CustomButton type='submit'>Register</CustomButton>
                    </Box>
                </form>
            </Box>

        </Box >
    )
}

export default RegistrationForm