import { createSlice } from "@reduxjs/toolkit";

const roomTypeSlice = createSlice({
    name: "roomType",
    initialState: {
        roomTypeData: null,
    },
    reducers: {
        setRoomTypeData: (state, action) => {
            state.roomTypeData = action.payload;
        },
    },
});

export const { setRoomTypeData } = roomTypeSlice.actions;
export const selectRoomTypeData = (state) => state.roomType.roomTypeData;
export default roomTypeSlice.reducer;
