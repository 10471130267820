import { createSlice } from "@reduxjs/toolkit";

const checkOutSlice = createSlice({
    name: "checkOut",
    initialState: {
        checkOutData: null,
    },
    reducers: {
        setCheckOutData: (state, action) => {
            state.checkOutData = action.payload;
        },
    },
});

export const { setCheckOutData } = checkOutSlice.actions;
export const selectCheckOutData = (state) => state.checkOut.checkOutData;
export default checkOutSlice.reducer;
