import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, Zoom, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: { xs: "1.44vw", md: "0.83vw" },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    fontSize: { xs: "1.44vw", md: "0.83vw" },
    //boxShadow: theme.shadows[1]
  },
}));

const CommonToolTip = ({
  title,
  children,
  placement = "top",
  arrowXOffset = 0,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={handleTooltipClose}
      onClick={() => setOpen((prev) => !prev)}
      sx={{
        cursor:"pointer"
      }}
    >
      <StyledTooltip
        open={open}
        onClose={handleTooltipClose}
        arrow
        title={title}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 400 }}
        placement={placement}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [arrowXOffset, -10],
                },
              },
            ],
          },
        }}
      >
        <Box component="span">{children}</Box>
      </StyledTooltip>
    </Box>
  );
};

CommonToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

export default CommonToolTip;
