import IconConstants from "./IconConstants"

export const paymentMethods = [
    { id: 1, value: "Cash" },
    { id: 2, value: "Card" },
    { id: 3, value: "UPI" },
]

export const dashboardViewTypes = [
    { value: 'Room View', icon: IconConstants.RoomViewIcon, highlightedIcon: IconConstants.HighlightedRoomViewIcon, order: 1 },
    { value: 'Floor View', icon: IconConstants.FloorViewIcon, highlightedIcon: IconConstants.HighlightedFloorViewIcon, order: 2 },
    // { value: 'Calendar View', icon: IconConstants.CalendarViewIcon, highlightedIcon: IconConstants.HighlightedCalendarViewIcon, order: 3 },
]

export const TAXES = {
    CGST : 'cgst',
    SGST :'sgst'
}

export const  INVOICE_TAXES =  [
    "cgst",
    "sgst"
]

export const CLEANING_STATUSES = {
    CLEAN: 'clean',
    DIRTY: 'dirty',
    ASSIGNED: 'assigned'
}

export const RESERVATION_STATUSES = {
    CONFIRMED: 'confirmed',
    TENTATIVE: 'tentative',
    CHECK_IN: 'check_in',
    CANCELLED: 'cancelled',
}

export const OCCUPANCY_STATUSES = {
    CHECK_IN: 'check_in',
    CHECK_OUT: 'check_out',
    ROOM_SHIFT: 'room_shift',
    RESERVED: 'reserved'
}

export const STAY_TYPES = {
    DAY: 'day',
    HOUR: 'hour',
    NOON: "noon"
}

export const ID_TYPES = {
    AADHAAR: 'aadhaar',
    PASSPORT: 'Passport',
}

export const BILLING_TYPE = {
    CUSTOMER: 'customer',
    COMPANY: 'company',
}


export const USER_ROLES ={
    ADMIN: 'admin',
    RECEPTIONIST: 'receptionist',
    HOUSEKEEPING_MANAGER : 'housekeeping_manager'
}

export const TAX_PERCENT = 12;