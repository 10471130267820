import { Box, Typography, Button, TextField, FormControl } from "@mui/material";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import { useTheme } from "@emotion/react";
import { errorToster, successToster } from "../../shared/toster/Toster";
import { format } from "date-fns";
import { Helper } from "../../utils/Helpers";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const { getLocalTimeFromUtc, isValidDate } = new Helper();

const ExtendStay = ({
  bookingData,
  handleCloseModal,
  resetData = () => {},
}) => {
  const { api } = ApiClient();
  const themeStyles = useTheme();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;

  const formConfig = () =>
    Yup.object().shape({
      checkin_date: Yup.date()
        .required("Check-in date must be a valid date")
        .typeError("Check-in date must be a valid date")
        .test({
          name: "valid-date",
          message: "Check-in date must be a valid date",
          test: (value) => isValidDate(value),
        }),
      expected_checkout_date: Yup.date()
        .required("Check-out date must be a valid date")
        .typeError("Check-out date must be a valid date")
        .test({
          name: "valid-date",
          message: "Check-out date must be a valid date",
          test: (value) => isValidDate(value),
        })
        .test({
          name: "checkout-after-checkin",
          message:
            "Check-out date must be greater than previous Check-out date",
          test: function (value) {
            console.log("value", value);
            return (
              !getLocalTimeFromUtc(bookingData?.checkout_date)?.localTime ||
              !value ||
              value > getLocalTimeFromUtc(bookingData?.checkout_date).localTime
            );
          },
        }),
    });

  const methods = useForm({
    resolver: yupResolver(formConfig()),
    defaultValues: {
      checkin_date:
        !!bookingData?.checkin_date &&
        getLocalTimeFromUtc(bookingData?.checkin_date)?.dateTimeString,
      expected_checkout_date:
        !!bookingData?.checkout_date &&
        getLocalTimeFromUtc(bookingData?.checkout_date)?.dateTimeString,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const { checkin_date, expected_checkout_date } = watch();

  const handleExtendStay = async (data) => {
    try {
      let response;
      if (bookingData?.booking_status === "check_in") {
        response = await api.post(END_POINT.OCCUPANCY.EXTEND_OCCUPANCY, {
          checkin_date: data?.checkin_date,
          expected_checkout_date: format(
            new Date(data?.expected_checkout_date),
            "yyyy-MM-dd HH:mm:ss"
          ),
          room_id: bookingData?.room_id,
          occupancy_id: bookingData?.occupancy_id,
          reservation_id: bookingData.reservation_id,
          stay_type_id: bookingData.stay_type_id,
          occupancy_price: bookingData.room_rent,
        });
      } else {
        response = await api.post(END_POINT.RESERVATION.EXTEND_RESERVATION, {
          expected_checkin_date: data?.checkin_date,
          expected_checkout_date: format(
            new Date(data?.expected_checkout_date),
            "yyyy-MM-dd HH:mm:ss"
          ),
          reserved_room_id: bookingData?.room_id,
          reservation_id: bookingData?.reservation_id,
        });
      }
      if (response.status === 200) {
        resetData();
        handleCloseModal();
        successToster(
          bookingData?.booking_status === "check_in" ? (
            <>
              Checkin for{" "}
              <span style={{ fontWeight: 700 }}>{bookingData.name}</span> in
              Room{" "}
              <span style={{ fontWeight: 700 }}>{bookingData.room_no}</span> is
              successfully extended.
            </>
          ) : (
            <>
              Reservation for{" "}
              <span style={{ fontWeight: 700 }}>{bookingData.name}</span> in
              Room{" "}
              <span style={{ fontWeight: 700 }}>{bookingData.room_no}</span> is
              successfully extended.
            </>
          )
        );
      }
    } catch (error) {
      errorToster("Another reservation found on the selected date");
      console.error("Error Extend Stay:", error);
    }
  };

  const fields = [
    {
      label: "Check in Date",
      name: "checkin_date",
      disable: true,
      minDate: format(new Date(), "yyyy-MM-dd HH:mm"),
    },
    {
      label: "Check out Date",
      name: "expected_checkout_date",
      disable: false,
      minDate:
        !!bookingData?.checkout_date &&
        format(new Date(bookingData?.checkout_date), "yyyy-MM-dd HH:mm"),
    },
  ];

  return (
    <Box
      sx={{
        width: {
          xs: "74vw",
          md: "41vw"},
          padding: { xs: "2% 0 3% 0", md: "0.5% 0 1.5% 0" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 auto 6% auto",
          width: "94%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "3.59vw", sm: "2.398vw", md: "1.39vw" },
          }}
        >
          Extend Stay
        </Typography>
        <Typography
          sx={{ fontSize: { md: "1.12vw" }, color: "#D9A62E", fontWeight: 500 }}
        >
          {bookingData?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "94%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ width: "15%" }}>
          <Box
            sx={{
              backgroundColor: "#F7F2E8",
              borderRadius: "50%",
              width: { xs: "10vw", sm: "5vw", md: "2.92vw" },
              height: {
                xs: "10vw",
                sm: "5vw",
                md: "2.92vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                color: "#252525",
                fontWeight: 600,
              }}
            >
              {bookingData?.room_no}
            </Typography>
          </Box>
        </Box>

        <form
          onSubmit={handleSubmit(handleExtendStay)}
          style={{ width: "85%" }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            {fields.map((formField, ind) => (
              <FormControl
                key={ind}
                sx={{ width: { xs: "100%", sm: "48%" }, marginBottom: "6%" }}
              >
                <Controller
                  {...methods}
                  name={formField.name}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      name={formField.name}
                      size="small"
                      type="datetime-local"
                      label={formField.label}
                      error={!!error?.message}
                      helperText={error ? error?.message : ""}
                      value={watch(formField.name)}
                      disabled={formField?.disable}
                      InputProps={{
                        sx: {
                          fontSize: { xs: "4.1vw", sm: "1.92vw", md: "1.12vw" },
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          fontSize: { xs: "4.1vw", sm: "1.92vw", md: "1.12vw" },
                        },
                      }}
                      FormHelperTextProps={{
                        sx: {
                          fontSize: {
                            xs: "3.08vw",
                            sm: "1.44vw",
                            md: "0.83vw",
                          },
                        },
                      }}
                      inputProps={{
                        min: formField.minDate,
                        max: formField.maxDate,
                      }}
                    />
                  )}
                />
              </FormControl>
            ))}

            <Button
              onClick={handleCloseModal}
              variant="outlined"
              sx={{
                width: "48%",
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                fontWeight: 700,
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              disabled={
                bookingData?.checkout_date == expected_checkout_date
                  ? true
                  : false
              }
              type="submit"
              sx={{
                width: "48%",
                color: contrastText,
                backgroundColor: primaryColor,
                fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                fontWeight: 700,
              }}
            >
              CONFIRM
            </Button>

            {/* <Typography
              sx={{
                color: "#ED705B",
                marginTop: "3%",
                fontSize: { sm: "1.44vw", md: "0.83vw" },
              }}
            >
              Room 204 is reserved from Feb 2/2/2024 - 7/2/2024.
              <br /> Please choose different room or shift the reservation
            </Typography> */}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ExtendStay;
