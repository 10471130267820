import { createSlice } from "@reduxjs/toolkit";

const updateProfileSlice = createSlice({
    name: "updateProfile",
    initialState: {
        isProfileUpdated: false,
    },
    reducers: {
        setIsProfileUpdated: (state, action) => {
            state.isProfileUpdated = action.payload;
        },
    },
});

export const { setIsProfileUpdated } = updateProfileSlice.actions;
export const selectIsProfileUpdated = (state) => state.updateProfile.isProfileUpdated;
export default updateProfileSlice.reducer;
