// Auth-guard.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthData } from "../store/redux/slices/Auth-slice";

const AuthGuard = ({ children }) => {

    const isAuthenticated =  useSelector(selectAuthData).isAuthenticated;
    if (!isAuthenticated) {
        
        return <Navigate to="/auth/login" />;
    }

    return <>{children}</>;
};

export default AuthGuard;
