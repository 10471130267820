import * as Yup from 'yup';

const AdminRegFormConfig = Yup.object().shape({

    hotel_name: Yup.string().required('Hotel Name is required'),
    hotel_email: Yup.string().required('Hotel Email is required'),
    hotel_address: Yup.string().required('Hotel Address is required'),
    hotel_land_line_number :Yup.string().required('Landline is required'),
    hotel_contact_number :Yup.string().required('Contact Number is required'),
    login_name:  Yup.string().required('Login Name is required'),
    name: Yup.string().required('User Name is required'),
    email:Yup.string().required('User Email is required'),
    password:  Yup.string().required('Password Name is required').min(8,'Password must be at least 8 characters.'),
    // confirmPassword:  Yup.string().required('Password Name is required'),

});

export default AdminRegFormConfig;
