// src/features/auth/authSlice.js
import { createSlice, } from '@reduxjs/toolkit';




const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        isAuthenticated: false,
        hotel: null,
    },
    reducers: {
        clearCredentials: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            sessionStorage.removeItem("user_key");
        },
        setCredentials: (state, action) => {
            const { user, hotel } = action.payload
            state.user = user;
            state.isAuthenticated = true;
            state.hotel = hotel
        },
    },

});

export const { clearCredentials, setCredentials } = authSlice.actions;
export const selectAuthData = (state) => state.auth;
export default authSlice.reducer;
