import * as Yup from 'yup';

const UserRegFormConfig = Yup.object().shape({

    hotel_id: Yup.number().required("Hotel is required"),
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
    contact :Yup.string().required('Contact is required'),
    login_name:  Yup.string().required('Login Name is required'),
    password:  Yup.string().required('Password is required').min(8,'Password must be atleast 8 characters.'),
});

export default UserRegFormConfig;   
