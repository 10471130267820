import { createSlice } from '@reduxjs/toolkit';
import ApiClient from '../../../../apis/Api-client';
import { END_POINT } from '../../../../utils/End-points';

// const { api } = ApiClient();
// const [
//     todayCheckin,
//     todayCheckout,
//     cleaningStatus,
//     roomCleaningStatus,
//     roomTypes,
//     bookingStatus,
//     extdArrival,
//     extdDeparture,
//     floors,
//     roomOccupancy,
//     allCheckinData,
//     paymentDetail,
//     pendingDueData
// ] = await Promise.all([
//     api.post(END_POINT.BOOKING.GET_ALL_CHECKIN, {}, "Error Fetching Checkin").then((res) => res.data.data),
//     api.post(END_POINT.BOOKING.GET_ALL_CHECKOUT, {}, "Error Fetching Checkout").then((res) => res.data.data),
//     api.get(END_POINT.STATUS.GET_ALL_CLEANING_STATUS, null, "Error Fetching cleaning status").then((res) => res.data.data),
//     api.post(END_POINT.HOUSE_KEEPING.GET_ROOM_CLEANING_STATUS, { data: {} }, "Error Fetching room cleaning status").then((res) => res.data.data),
//     api.get(END_POINT.ROOM_TYPE.GET_ALL_ROOM_TYPES, null, "Error Fetching Room Types").then((res) => res.data.data),
//     api.get(END_POINT.STATUS.GET_BOOKING_STATUS, null, "Error Fetching Booking Status").then((res) => res.data.data),
//     api.get(END_POINT.BOOKING.GET_ALL_EXPECTED_ARRIVAL, null, "Error Fetching Expected Arrival").then((res) => res.data.data),
//     api.get(END_POINT.BOOKING.GET_ALL_EXPECTED_DEPARTURE, null, "Error Fetching Expected Departure").then((res) => res.data.data),
//     api.get(END_POINT.FLOOR.GET_ALL_FLOORS, null, "Error Fetching Floors").then((res) => res.data.data),
//     api.get(END_POINT.ROOM.GET_ROOM_OCCUPANCY_BY_ROOM_TYPE, null, "Error Fetching Room Occupancy").then((res) => res.data.data),
//     api.post(END_POINT.BOOKING.GET_ALL_CHECKIN, {}, "Error Fetching Checkin").then((res) => res.data.data),
//     api.post(END_POINT.TAX.GET_PAYMENT_DETAIL, { "is_deleted": false }, "Error Fetching Payment Detail").then((res) => res.data.data),
//     api.get(END_POINT.BOOKING.GET_ALL_PENDING_DUES, null, "Error Fetching Pending Dues").then((res) => res.data.data),
// ]);

const dashboardApiSlice = createSlice({
    name: 'dashboardApi',
    initialState: {
        // todayCheckin,
        // todayCheckout,
        // cleaningStatus,
        // roomCleaningStatus,
        // roomTypes,
        // bookingStatus,
        // extdArrival,
        // extdDeparture,
        // floors,
        // roomOccupancy,
        // allCheckinData,
        // paymentDetail,
        // pendingDueData,
        triggered: true
    },
    reducers: {
        // setTodayCheckin: (state, action) => {
        //     state.todayCheckin = action.payload;
        // },
        // setTodayCheckout: (state, action) => {
        //     state.todayCheckout = action.payload;
        // },
        // setCleaningStatus: (state, action) => {
        //     state.cleaningStatus = action.payload;
        // },
        // setRoomTypes: (state, action) => {
        //     state.roomTypes = action.payload;
        // },
        // setExpectedArrival: (state, action) => {
        //     state.extdArrival = action.payload;
        // },
        // setExpectedDeparture: (state, action) => {
        //     state.extdDeparture = action.payload;
        // },
        // setRoomOccupancy: (state, action) => {
        //     state.roomOccupancy = action.payload;
        // },
        // setAllCheckin: (state, action) => {
        //     state.allCheckinData = action.payload;
        // },
        // setPaymentDetails: (state, action) => {
        //     state.paymentDetail = action.payload;
        // },
        // setPendingDue: (state, action) => {
        //     state.pendingDueData = action.payload;
        // },
        setTriggered: (state, action) => {
            state.triggered = action.payload
        }
    },
});
// export const { setTodayCheckin, setTodayCheckout, setCleaningStatus, setRoomTypes, setExpectedArrival, setExpectedDeparture, setRoomOccupancy, setAllCheckin, setPaymentDetails, setPendingDue } = dashboardApiSlice.actions;
export const { setTriggered } = dashboardApiSlice.actions;
export const selectTriggered = (state) => state.dashboardApi.triggered;
export default dashboardApiSlice.reducer;