import IconConstants from "../../../utils/IconConstants";
import { Helper } from "../../../utils/Helpers";
import { styled } from "@mui/material/styles";
import { ReceptionDashboard } from "./ReceptionDashboard";

export const helper = new Helper();

export const activityTabsData = [
  {
    name: "Arrival",
    active: IconConstants.ExpectedArrivalActive,
    inactive: IconConstants.ExpectedArrivalInActive,
  },
  {
    name: "Departure",
    active: IconConstants.ExpectedDepartureActive,
    inactive: IconConstants.ExpectedDepartureInActive,
  },
  {
    name: "Payment Due",
    active: IconConstants.PaymentDueActive,
    inactive: IconConstants.PaymentDueInActive,
  },
];

export const ScrollContainer = styled("div")({
  display: "flex",
  overflowX: "auto",
  maxWidth: "100%",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  scrollbarWidth: "none",
  minHeight:"7vw",
});

// Styled component for the scroll item
export const ScrollItem = styled("div")({
  minWidth: "100%",
  // height: 120,
  backgroundColor: (props) => props.color || "primary",
  // marginRight: "16px",
  flexShrink: 0,
});


export default ReceptionDashboard;
