import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./core/store/redux/Root-reducer";
import loggerMiddleware from './core/store/redux/Logger-middleware';

const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, loggerMiddleware),
});

export default store;

