import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import ApiClient from "../../../apis/Api-client";
import { END_POINT } from "../../../utils/End-points";
import "./Login-component.scss";
import { errorToster, successToster } from "../../../shared/toster/Toster";
import { PersonRounded, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import loginFormConfig from "../../../config/forms/LoginForm-config";
import IconConstants from "../../../utils/IconConstants";
import { Helper } from "../../../utils/Helpers";
const { getPayloadFromToken } = new Helper();

const LoginForm = () => {
  const navigate = useNavigate();
  const { api } = ApiClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginFormConfig),
  });

  const onSubmitHandler = async (finalData) => {
    try {
      let loginRes = await api.post(
        END_POINT.AUTH.LOGIN,
        finalData,
        "Unable To Login User!"
      );
      if (loginRes?.status === 200) {
        api.setRequestHeader(
          "Authorization",
          `Bearer ${loginRes?.data?.data?.accessToken}`
        );
        const tokenPayload = getPayloadFromToken(
          loginRes?.data?.data?.accessToken
        );
        sessionStorage.setItem("user_key", tokenPayload.user.user_key);
        navigate("/");
        setTimeout(() => {
          successToster(
            <>
              Welcome,{" "}
              <span style={{ fontWeight: 600 }}>
                {loginRes?.data?.data?.name}
              </span>
            </>
          );
        }, 600);
      }
    } catch (error) {
      if (error?.response?.status == 422) {
        errorToster(error?.response?.data?.msg);
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box className="mainBox">
      <Box
        className="flexBox"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "92%", sm: "80.5%", md: "45.85%" },
          height: { xs: "105vw", sm: "64.78vw", md: "37.5vw" },
          border: { xs: "1px solid #D6A01E", sm: "0.1px solid #A7A7A7" },
          boxShadow: { sm: "0px 12px 10px 1px #00000040" },
          backgroundColor: "#FFFFFF",
        }}
      >
        <Box
          className="flexChild"
          sx={{ display: { xs: "none", sm: "initial" } }}
        ></Box>
        <Box className="flexChild2" sx={{ width: { xs: 1, sm: "45%" } }}>
          <Box className="formBox">
            <Box
              sx={{
                width: { sm: "9.592vw", md: "5.556vw" },
                height: { sm: "9.272vw", md: "5.37vw" },
                margin: "0 auto 8% auto",
              }}
            >
              <img
                src={IconConstants.GuestHivesLogo}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box
              sx={{
                padding: "5% 0",
                marginBottom: "5%",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              <Typography
                id="welcomeTypo"
                sx={{ fontSize: { xs: "4.62vw", sm: "2.398vw", md: "1.39vw" } }}
              >
                Welcome to Guest Hives
              </Typography>
              <Typography
                id="subTypo"
                sx={{ fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } }}
              >
                Login to your Account
              </Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "5.13vw", sm: "2.88vw ", md: "1.67vw" },
                }}
              >
                <TextField
                  type="text"
                  name="login_name"
                  placeholder="Username"
                  size="small"
                  fullWidth
                  error={!!errors.login_name}
                  helperText={errors.login_name?.message}
                  onInput={(e) => {
                    let { value } = e.target;
                    e.target.value = value.replace(/^\s+/, "");
                  }}
                  inputProps={{ maxLength: 20 }}
                  InputProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <PersonRounded
                            fontSize="small"
                            sx={{ color: "#c8c8c8" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: { xs: "#E7AC20", sm: "#A7A7A7" },
                    },
                  }}
                  {...register("login_name")}
                />

                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  fullWidth
                  size="small"
                  onInput={(e) => {
                    let { value } = e.target;
                    e.target.value = value.replace(/^\s+/, "");
                  }}
                  inputProps={{ maxLength: 12 }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: { xs: "#E7AC20", sm: "#A7A7A7" },
                    },
                  }}
                  {...register("password")}
                  InputProps={{
                    sx: {
                      fontSize: { xs: "3.59vw", sm: "1.68vw", md: "0.97vw" },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <Visibility
                              fontSize="small"
                              sx={{ color: "#c8c8c8" }}
                            />
                          ) : (
                            <VisibilityOff
                              fontSize="small"
                              sx={{ color: "#c8c8c8" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  id="loginButton"
                  sx={{
                    fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.12vw" },
                    fontWeight: "600",
                    // padding: "1.5%",
                    color: "#FFFFFF",
                    background:
                      "linear-gradient(180deg, #E7AC20 0%, rgba(0, 0, 0, 0) 809.09%)",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                      color: "#E98D12",
                      fontWeight: "600",
                    },
                    marginTop: "6%",
                  }}
                >
                  LOGIN
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
