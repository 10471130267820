import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Rooms: [],
};

const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    addRooms: (state, action) => {
        const{roomNo, roomType} = action.payload 
      state.Rooms.push({ roomNo: roomNo, roomType: roomType });
    },
    deleteRooms: (state, action) => {
      state.Rooms = state.Rooms.filter((room) => room.roomNo !== action.payload.roomNo);
    },
  },
});

export const { addRooms, deleteRooms } = roomsSlice.actions;

export default roomsSlice.reducer;