import { createSlice } from "@reduxjs/toolkit";

const bookingStatusSlice = createSlice({
    name: "bookingStatus",
    initialState: {
        roomTypeData: null,
    },
    reducers: {
        setBookingStatusData: (state, action) => {
            state.bookingStatusData = action.payload;
        },
    },  
});

export const { setBookingStatusData } = bookingStatusSlice.actions;
export const selectBookingStatusData = (state) => state.bookingStatus?.bookingStatusData;
export default bookingStatusSlice.reducer;
