import * as Yup from "yup";

export const AddOrRemoveFormConfig = Yup.object().shape({
  extra_persons: Yup.array().of(
    Yup.lazy((value, schema) => {
      return Yup.object().shape({
        addon_name: Yup.string()
          .required("Name is required")
          .min(3, "Min 3 characters"),
        addon_price: Yup.number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required("Rent is required"),
      });
    })
  ),
});

export const AddOrRemoveTableConfig = Yup.object().shape({
  addon_name: Yup.string().min(3, "Min 3 char").required("Name required"),
  addon_price: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Rent is required")
    .min(0, "Rent must be greater than 0"),
  actual_checkout_date: Yup.date().nullable(),
});
